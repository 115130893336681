@import "normalize";
@import "fonts";
@import "bootstrap";
@import "fontawesome-all";
@import "bootstrap-datepicker3";

// GENERAL

.help-block.form-error {
  color: $danger; }

form {
  margin: 25px 0; }


// HEADER
header {
  margin-bottom: 25px; }

// Footer
footer {
  padding: 15px 0;
  margin-top: 20px; }
